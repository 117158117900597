import { Injectable, inject, signal, computed } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { TranslocoService } from '@jsverse/transloco';
import { HesToasterService } from '@shared/services/hes-toaster.service';
import { GenericErrorFeedbackService } from '@shared/services/generic-error-feedback.service';
import { CometChat } from '@cometchat/chat-sdk-javascript';
import { ConversationType } from '@shared/enums';

@Injectable({
  providedIn: 'root',
})
export class ChatService {
  private readonly translocoService = inject(TranslocoService);
  private readonly toasterService = inject(HesToasterService);
  private readonly genericModalSerivce = inject(GenericErrorFeedbackService);
  private triggerDeleteFunctionSource = new Subject<void>();
  triggerDeleteFunction$ = this.triggerDeleteFunctionSource.asObservable();
  private draftMessageSentSource = new Subject<CometChat.BaseMessage>();
  draftMessageSent$ = this.draftMessageSentSource.asObservable();
  isShowStartChatComponent = signal<boolean>(false);

  isChatLoggedIn = computed(() => {
    const token = localStorage.getItem('chatAuthToken');
    return !(token == undefined || token == 'undefined' || token == null);
  });
  constructor(private http: HttpClient) {}
  BE_API_BASE_URL = environment.BE_API_BASE_URL;

  initiateBroadcastChat(payload: any): Observable<any> {
    return this.http.post(`${this.BE_API_BASE_URL}/chats`, payload);
  }

  displayChatAuthMessage() {
    this.toasterService.error(
      this.translate('global.wrong_msg.title'),
      this.translate('chats.initiating_chat_error.txt'),
    );
  }

  deleteConversation(uid: string, type: ConversationType) {
    this.genericModalSerivce.show(
      () => {
        this.handleChatDelete(uid, type);
      },
      {
        modalTitle: this.translate('chats.delete_chat_msg.txt'),
        modalMessage: '',
        primaryBtnStr: this.translocoService.translate('global.delete.btn'),
      },
    );
  }

  private handleChatDelete(uid: string, type: ConversationType) {
    CometChat.deleteConversation(uid, type).then(
      async () => {
        this.toasterService.success(
          this.translate('chats.chat_deleted_successfully.txt'),
        );
        this.triggerDeleteFunctionSource.next();
      },
      (error: CometChat.CometChatException) => {
        this.toasterService.showGlobalWrongMessage(error.message);
      },
    );
  }
  private translate(key: string, params: object = {}): string {
    return this.translocoService.translate(key, params);
  }

  chatDraftMessageSent(message: CometChat.BaseMessage) {
    this.setStartChatComponentVisibility(false);
    this.draftMessageSentSource.next(message);
  }

  setStartChatComponentVisibility(isVisible: boolean) {
    this.isShowStartChatComponent.set(isVisible);
  }

  getCometChatUser = (uid: string) => {
    return new Promise<CometChat.User>((resolve, reject) => {
      CometChat.getUser(uid).then(
        (user: CometChat.User) => {
          resolve(user);
        },
        (error: CometChat.CometChatException) => {
          reject(error);
        },
      );
    });
  };

  registerUserToCometChat = (user: CometChat.User) => {
    return new Promise<CometChat.User>((resolve, reject) => {
      CometChat.createUser(user, environment.COMET_AUTH_KEY).then(
        (createdUser: CometChat.User) => {
          resolve(createdUser);
        },
        (error: CometChat.CometChatException) => {
          reject(error);
        },
      );
    });
  };
}
